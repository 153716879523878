<!-- 余额提现 -->
<template>
    <div style="text-align: left;background: #FFFFFF;">
        <el-card>
            <h2 class="text-center">余额提现</h2>
            <el-form ref="form" :model="form" :rules="rules" label-width="80px" class="m-t-40">
                <el-form-item label="提现方式" v-if="0">
                    <el-radio-group v-model="form.resource">
                        <el-radio label="在线提现"></el-radio>
                        <el-radio label="线下提现"></el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="收款方式">
                    <span class="btn m-r-10" :class="{'select':form.withdrawBank==0}" @click="form.withdrawBank=0">
                        <img src="@/assets/images/stockCar/WeChat.png" class="btnImg">
                        微信
                        <img src="@/assets/images/stockCar/corner.png" class="corner">
                    </span>
                    <span class="btn" :class="{'select':form.withdrawBank==1}" @click="form.withdrawBank=1"> 
                        <img src="@/assets/images/stockCar/Alipay.png" class="btnImg">
                        支付宝
                        <img src="@/assets/images/stockCar/corner.png" class="corner">
                    </span>
                </el-form-item>
                <el-form-item label="提现金额" prop="fee">
                    <el-input v-model.number="form.fee"></el-input>
                    *每次最低提现金额100元,当前余额 <span class="c-m">{{userInfo.userBalance||0.00}}</span>元
                </el-form-item>
                <el-form-item label="真实姓名" prop="withdrawReName">
                    <el-input v-model="form.withdrawReName" placeholder="收款账号真实姓名验证"></el-input>
                </el-form-item>
				<el-form-item label="手机号码">
				    <div>{{ userInfo.userPhoneNumber }}</div>
				</el-form-item>
				<el-form-item label="验证码" prop="code">
				    <el-input v-model="form.code" autocomplete="off" placeholder="请输入短信验证码">
				      <el-button @click="getRegisterPhoneCode" :disabled="!showGetCode" type="text"
				                 style="padding-right:10px;color:#0366C3;" slot="suffix">
				        <span v-if="showGetCode">获取验证码</span>
				        <span v-else class="count">{{ codeTime }} s</span>
				      </el-button>
				    </el-input>
				</el-form-item>
				<el-form-item label="收款账号" prop="bankAccount" v-if="form.withdrawBank == 1">
				    <el-input v-model="form.bankAccount" placeholder="请输入支付宝收款账户"></el-input>
				</el-form-item>
                <el-form-item label="" v-if="0">
                    <el-checkbox v-model="form.desc">同意《乐搜家余额提现条款》</el-checkbox>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">立即提现</el-button>
                    <el-button @click="$oucy.back()">返回</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { localSet, localGet, localDel } from "@/store/store"
import { userMaterial,balance } from "@/service"
const formDefault = {
                bankAccount: '',//收款账户
                fee: null,//    提现金额
                withdrawBank: 0,//收款账户类型 0 微信账号零钱 1 支付宝 2 中国银行 3 招商银行
                withdrawReName: '',//收款人真实姓名，
				appIdCategory:0 ,//收款账户类型 =0 微信账号零钱 的时候有效
				code:'' //短信验证码
};
export default {
    name: "withdrawal",
    components: {},
    data() {
        return {
            // 验证
            rules: {
                fee: [
                    { required: true, message: '请输入提现金额', trigger: 'blur' }
                ],
                withdrawReName: [
                    { required: true, message: '请输入真实姓名', trigger: 'blur' }
                ],
				bankAccount: [
				    { required: true, message: '请输入收款账户', trigger: 'blur' }
				],
				code: [
				  {required: true, message: '请输入验证码', trigger: 'blur'},
				]
            },
            // 表单
            form: Object.assign({},formDefault),
            userInfo:{},
			codeTime: 0,
			codeTimer: null,
			showGetCode: true,
			visible: false,
        }
    },
    mounted() {
        this.queryUserMaterial()
    },
    methods: {
        // 查询用户信息
        queryUserMaterial(){
            userMaterial.queryUserMaterial({}).then(res=>{
                this.userInfo=res
				const me = this;
				oucy.getCodeTime(this.userInfo.id).then(res => {
				  me.codeTime = Number(res);
				  me.codeTimer = setInterval(() => {
				    if (me.codeTime > 0) {
				      me.showGetCode = false;
				      me.codeTime--;
				    } else {
				      me.showGetCode = true;
				    }
				  }, 1000);
				});
            })
        },
        // 点击提现
        onSubmit() {
            // console.log('submit!');
            this.$refs.form.validate((val)=>{
                if(val){
					let msg = `即将提现到微信账号“ ${this.userInfo.detailWechatNickname} ”中,是否继续提现？`;
					
					if(this.form.withdrawBank == 1){
						msg = `即将提现到支付宝收款账号“ ${this.form.bankAccount} ”中,是否继续提现？`;						
					}
                    this.$confirm(msg, '注意', {
                        confirmButtonText: '继续提现',
                        cancelButtonText: '取消提现',
                        // type: 'warning'
                    }).then(() => {
                        // console.log('点了确认')
                       this.createWithdraw() 
                    }).catch(() => {
                        // console.log('点了取消')
                    });
                }
            })

        },
		getRegisterPhoneCode(){
			oucy.postRequest('/client/financial/balance/accSetupSendCode', {
			  uuid: this.userInfo.id
			}).then(res => {
			  this.codeTime = Number(res.seconds);
			  this.$notify({
			    title: '短信发送成功',
			    message: '验证码已发送，请留意短信通知',
			    type: 'success'
			  });
			});
		},
        // 提交接口
        createWithdraw(){        
			// 微信支付 收款账号直接拿userInfo.userWechatOpenidMpLesoujia2
			if(this.form.withdrawBank == 0) {
				this.form.bankAccount = this.userInfo.userWechatOpenidMpLesoujia2;
			}			
            balance.createWithdraw(this.form).then(res=>{
                this.$alert('提现成功','提示');
                this.userInfo.userBalance-=this.form.fee;
				setTimeout(() => {
				    this.$refs['form'].resetFields()
				});
				this.form = Object.assign({},formDefault);
            })
        },

    }
}
</script>
<style scoped lang="less">
.btn {
    line-height: 1.2;
    display: inline-block;
    padding: 6px 36px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #EAEAEA;
    position: relative;
    cursor: pointer;
    vertical-align: middle;

    .corner {
        position: absolute;
        right: 0;
        bottom: 0;
        display: none;
    }

    &.select {
        border: 1px solid #2090FF;
        color: #2090ff;

        .corner {
            display: inline-block;
        }
    }

    .btnImg {
        vertical-align: inherit;
    }
}

.btn+.btn {
    margin-left: 3px;
}
</style>